import React, { useState } from 'react';
import Header from '../components/Header';
import './styles.css';

const Home = () => {
  const [isModalOpen, setIsModalOpen] = useState(false); // Modal görünürlüğünü kontrol eden state

  return (
    <div className="main-container">
      <Header />
      <div className="gumby-text">GUMBY</div>
      <div className="description-text">
        Welcome to GumbyMeme, where the Galaxy of Memes meets the Crypto Universe.
        Join our cosmic crew and get ready for a meme-fest featuring Gumby
        that's out of this world! Together, we'll ride the wave of meme madness to the
        stars and beyond, with Gumby leading the way.
      </div>
      <div className="additional-buttons">
        <button className="buy-button" onClick={() => window.open('https://t.me/Gumby_sol', '_blank')}>Join the Pride</button>
        <button
          className="buy-button"
          id="chart-button"
          onClick={() => setIsModalOpen(true)} // Butona tıklandığında modal açılır
        >
          Chart
        </button>
      </div>
      <div className="world-cloudy-container">
        <img src="images/worldcloudy.svg" alt="World Cloudy" className="background-image" />
        <img src="images/rocket.svg" alt="Rocket" className="rocket-image" />
        <img src="images/gumbyspace.svg" alt="Gumby Space" className="gumbyspace-image" />
        <img src="images/gumbyhero.svg" alt="Gumby Hero" className="gumbyhero-image" />
        <div className="about-gumby">
          <h2>ABOUT GUMBY</h2>
          <p>Gumby isn't just a meme; it's a movement within the Solana ecosystem.
            Inspired by the playful spirit of Gumby, we’re bringing a wave of creativity and humor
            to the world of crypto. Join us as we merge meme culture with the innovation of Solana,
            uniting a community of enthusiasts ready to explore the future of decentralized fun.
          </p>
        </div>
      </div>
      <div className="lower-section">
        <div className="info-box">
          <div className="info-row">
            <div className="info-item">Total Supply</div>
            <div className="info-item">Liquidity</div>
            <div className="info-item">CA</div>
          </div>
          <div className="info-row">
            <div className="info-item">100,000,000</div>
            <div className="info-item">50% Burn After Launch</div>
            <div className="info-item">After Launch</div>
          </div>
        </div>
        <img src="images/tokenomics.svg" alt="Tokenomics" className="tokenomics-svg" id="tokenomics-section" />
      </div>
      <div className="lower-description">
        Join our crew and embark on the Gumby journey within the Solana ecosystem.
        Whether you're here to hodl, create, or simply enjoy the memes,
        there's a spot for you in our cosmic adventure. With Gumby, it’s not just about
        reaching the stars—it’s about enjoying the ride and embracing the playful spirit every step of the way.
      </div>
      <div className="utilities-text">GUMBY UTILITIES</div>
      <div className="utility-container">
        <div className="utility-column-1">
          <div className="utility-box">LIQUIDITY 50%</div>
          <div className="utility-box long">
            50% of the Total Supply will be added to Liquidity Pool.
          </div>
        </div>
        <div className="utility-column-2">
          <div className="utility-box">PRESALE 10%</div>
          <div className="utility-box long">
            Only limited amount of tokens will be sold on presale,
            so only the special ones will be at the ship on time.
          </div>
        </div>
      </div>
      <div className="footer-section">
        <div className="footer-background">
          <img src="images/cloudygreen.svg" alt="Cloudy Green" className="footer-image" />
          <div className="roadmap-head">ROADMAP</div>
          <img src="images/card_rocket_new.svg" alt="Card Rocket" className="card-rocket-svg" />
          <div className="roadmap-text">Welcome to GumbyMeme, where the Galaxy of Memes meets the Crypto Universe.
            Join our cosmic crew and get ready for a meme-fest featuring
          </div>
          <div className="memestiny-head">OUR MEMESTINY</div>
          <div className="memestiny-box-container">
            <img src="images/Gumby1.svg" alt="Gumby 1" className="memestiny-img" />
            <img src="images/gumby2.svg" alt="Gumby 2" className="memestiny-img" />
          </div>
          <div className="memestiny-box-full">
            <img src="images/Gumby3.svg" alt="Gumby 3" className="memestiny-img-full" />
          </div>
          <div className="buy-guide" id="how-to-buy-section">HOW TO BUY GUMBY</div>
          <div className="buy-guide-container">
            <div className="buy-guide-row">
              <div className="buy-guide-box">
                <h1>Step 1 Create a Phantom Wallet</h1>
                <h2>
                  First of all create a Phantom Wallet which is used for Solana Network by clicking
                  <div>
                    <p onClick={() => window.open('https://phantom.app/download', '_blank')}>here</p>
                  </div>
                  *If you already have, then you can just use it.
                </h2>
              </div>
              <div className="buy-guide-box">
                <h1>Step 2 Fund your wallet with Solana</h1>
                <h2>Since Solana is the governance token at Solana Network, send some $SOL to your wallet.</h2>
              </div>
            </div>
            <div className="buy-guide-row">
              <div className="buy-guide-box">
                <h1>Step 3 Join Presale or Purchase on DEX</h1>
                <h2>You can join the presale or if you missed it but still want to be a part of this
                  journey, you can use DEX's for it. Copy the contract address and search it on the DEX's page.
                </h2>
                <p> *Contract address will be shared after token launch.</p>
              </div>
              <div className="buy-guide-box">
                <h1>Finally... Get into $GUMBY Starship</h1>
                <h2>Get into the Gumby Starship before we rocket to the MOOOOOONNNNNNNN!!!!!!</h2>
              </div>
            </div>
          </div>
          <div className="social-box">
            <div className="social-content">
              <h2>JOIN THE PRIDE AND CONNECT WITH FELLOW GUMBY ENTHUSIASTS</h2>
              <div className="social-icons-container">
                <div className="social-icon-box">
                  <a href="https://x.com/Gumby_sol" target="_blank" rel="noopener noreferrer">
                    <img src="images/twitter.png" alt="Twitter Icon" className="social-icon-small" />
                  </a>
                  <p className="social-handle">@Gumby_sol</p>
                </div>
                <img src="images/gumbysocialmedia-01.svg" alt="Gumby Social Media" className="social-icon" />
                <div className="social-icon-box">
                  <a href="https://t.me/Gumby_sol" target="_blank" rel="noopener noreferrer">
                    <img src="images/telegram.svg" alt="Telegram Icon" className="social-icon-small" />
                  </a>
                  <p className="social-handle">@Gumby_sol</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Modal */}
      {isModalOpen && (
        <div id="chartModal" className="modal">
          <div className="modal-content">
            <span className="close" onClick={() => setIsModalOpen(false)}>&times;</span>
            <p>Chart will be available shortly after the Token Launch</p>
          </div>
        </div>
      )}
    </div>
  );
};

export default Home;
