import React from 'react';
import { Buffer } from 'buffer';
import { Routes, Route } from 'react-router-dom';
import Home from './pages/Home';
import PresalePage from './pages/PresalePage';

window.Buffer = Buffer;

const App = () => {
  return (
    <div>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/PublicSale" element={<PresalePage />} />
      </Routes>
    </div>
  );
};

export default App;
