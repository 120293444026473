import React from 'react';
import { useNavigate } from 'react-router-dom';
import logo from './images/gumbylogo.svg'; // Görselin yolu
import './styles.css';

const Header = () => {
  const navigate = useNavigate();

  const handleScroll = (elementId) => {
    const element = document.getElementById(elementId);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const handleButtonClick = (url) => {
    navigate(url);
  };

  return (
    <header className="header">
      <div className="left-buttons">
        <button className="transparent-button" onClick={() => handleScroll('tokenomics-section')}>Gumbysonic</button>
        <button className="transparent-button" onClick={() => window.open('https://t.me/Gumby_sol', '_blank')}>Community</button>
        <button className="transparent-button" onClick={() => handleScroll('how-to-buy-section')}>How to Buy</button>
      </div>
      <img src={logo} alt="Gumby Logo" className="logo-image" />
      <div className="right-button">
        {/* <button className="buy-button" onClick={() => handleButtonClick('/PublicSale')}>Buy $GUMBY</button> */}
        {/*<button className="buy-button">Buy $GUMBY</button>*/}
      </div>
    </header>
  );
};

export default Header;
