import React, { useEffect, useState } from 'react';
import { Connection, PublicKey, clusterApiUrl, SystemProgram } from '@solana/web3.js';
import { AnchorProvider, Program, BN } from '@project-serum/anchor';
import idl from './idl.json';
import { Buffer } from 'buffer';
import logo from './images/gumbylogo.svg';
import './presale_copy.css';

window.Buffer = Buffer;

const programID = new PublicKey('GL3MsBT6hABwUVVZjF4vYvUZm9YbWtc9H7Vjha9Niewa');
const tokenSaleAddress = new PublicKey('574797r71MVccECNoJVtZhZzdHMm5hbupfJfuBLovsYJ');
const network = "https://wider-sparkling-feather.solana-mainnet.quiknode.pro/7544d7eee378da434103568be4125acf567afa60";
const opts = { preflightCommitment: 'processed' };

const PresalePage = () => {
  const [walletAddress, setWalletAddress] = useState(null);
  const [connection, setConnection] = useState(null);
  const [provider, setProvider] = useState(null);
  const [program, setProgram] = useState(null);
  const [solAmount, setSolAmount] = useState('');
  const [isPaused, setIsPaused] = useState(false);
  const [presaleStartTime, setPresaleStartTime] = useState(0);
  const [modalMessage, setModalMessage] = useState('');
  const [vestingInfo, setVestingInfo] = useState(null);
  const [countdownTime, setCountdownTime] = useState('');
  const [countdownVisible, setCountdownVisible] = useState(true);
  const [buySectionVisible, setBuySectionVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [totalSolSold, setTotalSolSold] = useState(0);

  // Cüzdanı bağlama fonksiyonu
  const connectWallet = async () => {
    if (window.solana && window.solana.isPhantom) {
      try {
        const resp = await window.solana.connect();
        setWalletAddress(resp.publicKey.toString());
        console.log('Connected to wallet:', resp.publicKey.toString());
      } catch (err) {
        console.error('Failed to connect wallet:', err);
      }
    } else {
      alert('Lütfen Phantom cüzdan eklentisini yükleyin.');
    }
  };

  useEffect(() => {
    // Otomatik cüzdan bağlantısı
    if (window.solana && window.solana.isPhantom) {
      window.solana.connect({ onlyIfTrusted: true })
        .then((resp) => {
          setWalletAddress(resp.publicKey.toString());
          console.log('Connected to wallet:', resp.publicKey.toString());
        })
        .catch((err) => {
          console.error('Wallet not connected:', err);
        });
    }
  }, []);

  useEffect(() => {
    if (walletAddress && window.solana && window.solana.isPhantom) {
      const conn = new Connection(network, opts.preflightCommitment);
      setConnection(conn);

      // Sağlayıcıyı oluştur ve kontrol et
      const provider = new AnchorProvider(conn, window.solana, opts);
      setProvider(provider);

      console.log('Provider created with wallet:', provider.wallet.publicKey.toString());

      const program = new Program(idl, programID, provider);
      setProgram(program);
    } else {
      console.error('Phantom wallet is not available or wallet address is not set');
    }
  }, [walletAddress]);

  useEffect(() => {
    if (program) {
      const fetchTokenSaleData = async () => {
        try {
          const tokenSaleAccount = await program.account.tokenSale.fetch(tokenSaleAddress);

          const totalSolSoldInSol = tokenSaleAccount.totalSolSold.toNumber() / 1e9;

          const tokenSaleData = {
            tokenPrice: tokenSaleAccount.tokenPrice.toNumber(),
            owner: tokenSaleAccount.owner.toString(),
            presaleStartTime: tokenSaleAccount.presaleStartTime.toNumber(),
            totalSolSold: totalSolSoldInSol.toFixed(2),
            totalTokensSold: tokenSaleAccount.totalTokensSold.toString(),
            isPaused: tokenSaleAccount.isPaused,
            totalWithdrawnSol: tokenSaleAccount.totalWithdrawnSol.toString(),
          };

          console.log('Fetched Token Sale Data:', tokenSaleData);

          setTotalSolSold(totalSolSoldInSol);

          if (walletAddress) {
            const userPublicKey = new PublicKey(walletAddress);
            const [vestingInfoPDA] = await PublicKey.findProgramAddress(
              [Buffer.from('vesting'), userPublicKey.toBuffer()],
              programID
            );

            try {
              const vestingInfoAccount = await program.account.vestingInfo.fetch(vestingInfoPDA);

              const vestingData = {
                user: vestingInfoAccount.user.toString(),
                balance: vestingInfoAccount.balance.toString(),
                totalSolSent: (vestingInfoAccount.totalSolSent.toNumber() / 1e9).toFixed(2),
              };

              console.log('Fetched Vesting Info Data:', vestingData);
              setVestingInfo(vestingData);
            } catch (error) {
              console.log('No Vesting Info for this user.');
            }
          }

          setIsPaused(tokenSaleData.isPaused);
          setPresaleStartTime(tokenSaleData.presaleStartTime);

          if (tokenSaleData.presaleStartTime > 0) {
            startCountdown(tokenSaleData.presaleStartTime);
          } else {
            setCountdownVisible(false);
            setBuySectionVisible(true);
          }

        } catch (error) {
          console.error('Failed to fetch token sale data:', error);
        }
      };
      fetchTokenSaleData();
    }
  }, [walletAddress, program]);

  const startCountdown = (startTime) => {
    const targetTime = 1726488031 * 1000;

    const countdownInterval = setInterval(() => {
      const now = Date.now();
      const distance = targetTime - now;

      if (distance > 0) {
        const hours = Math.floor((distance / (1000 * 60 * 60)));
        const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((distance % (1000 * 60)) / 1000);

        setCountdownTime(`${hours} hr ${minutes} min ${seconds} sec`);
      } else {
        clearInterval(countdownInterval);
        setCountdownTime('Time is up');
        setCountdownVisible(false);
        setBuySectionVisible(true);
      }
    }, 1000);

    return () => clearInterval(countdownInterval);
  };

  useEffect(() => {
    startCountdown();
  }, []);

  const handleBuy = async () => {
    if (!program) {
      console.error('Program is not initialized');
      return;
    }

    if (!walletAddress) {
      console.error('Wallet address is not provided');
      setModalMessage('Please connect your wallet.');
      return;
    }

    const currentTime = Math.floor(Date.now() / 1000);

    if (isPaused) {
      setModalMessage('Presale not active now.');
      return;
    } else if (!isPaused && currentTime < presaleStartTime) {
      setModalMessage("Presale hasn't started yet.");
      return;
    }

    const lamports = parseFloat(solAmount) * 1e9;
    if (isNaN(lamports) || lamports <= 0) {
      console.error('Invalid SOL amount');
      setModalMessage('Please enter a valid SOL amount.');
      return;
    }

    try {
      const balance = await connection.getBalance(new PublicKey(walletAddress));
      if (balance < lamports) {
        console.error('Not enough SOL in wallet to cover the transaction');
        setModalMessage('Not enough SOL in wallet to cover the transaction.');
        return;
      }

      const userPublicKey = new PublicKey(walletAddress);
      const [vestingInfoPDA] = await PublicKey.findProgramAddress(
        [Buffer.from('vesting'), userPublicKey.toBuffer()],
        programID
      );

      setIsLoading(true);

      const tx = await program.methods
        .buyToken(new BN(lamports))
        .accounts({
          user: userPublicKey,
          tokenSale: tokenSaleAddress,
          targetWallet: new PublicKey('FpT5xwoD3yEq8azRMDWAskcHFTkhtKVNHFC2apRr1Ce3'),
          vestingInfo: vestingInfoPDA,
          systemProgram: SystemProgram.programId,
        })
        .rpc();

      console.log('Transaction successful:', tx);



      const vestingInfoAccount = await program.account.vestingInfo.fetch(vestingInfoPDA);

      const vestingData = {
        user: vestingInfoAccount.user.toString(),
        balance: vestingInfoAccount.balance.toString(),
        totalSolSent: (vestingInfoAccount.totalSolSent.toNumber() / 1e9).toFixed(2),
      };

      setVestingInfo(vestingData);

      const tokenSaleAccount = await program.account.tokenSale.fetch(tokenSaleAddress);
      const updatedTotalSolSold = tokenSaleAccount.totalSolSold.toNumber() / 1e9;
      setTotalSolSold(updatedTotalSolSold);

    } catch (error) {
      console.error('Transaction failed:', error);
      setModalMessage('Transaction failed. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  const progressPercentage = Math.min((totalSolSold / 1600) * 100, 100);

  return (
    <div>
      <header className="header">
        <img src={logo} alt="Gumby Logo" className="logo-image" />
      </header>

      {countdownVisible ? (
        <div className="countdown">{countdownTime}</div>
      ) : (
        <div className={`buy-section ${buySectionVisible ? 'visible' : ''}`}>
          <input
            type="text"
            placeholder="Enter SOL amount"
            value={solAmount}
            onChange={(e) => setSolAmount(e.target.value)}
            className="sol-input"
          />

          {isLoading ? (
            <div>Please wait, transaction in progress...</div>
          ) : walletAddress ? (
            <button onClick={handleBuy} className="purchase-button">Buy $GUMBY</button>
          ) : (
            <button onClick={connectWallet} className="wallet-button">Connect Wallet</button>
          )}

          {walletAddress ? (
            <div className="wallet-info">
              <p><strong>Wallet Address:</strong> {walletAddress}</p>
              {vestingInfo && (
                <p><strong>Total Gumby Bought by this Wallet:</strong> {vestingInfo.balance} $GUMBY</p>
              )}

              <div className="progress-container">
                <p><strong>Presale Progress:</strong></p>
                <div className="progress-bar">
                  <div
                    className="progress-bar-fill"
                    style={{ width: `${progressPercentage}%` }}
                  ></div>
                </div>
                <p>{totalSolSold.toFixed(2)} SOL / 1600 SOL</p>
              </div>

            </div>
          ) : (
            <div className="wallet-info">
              <p>Please connect your wallet to see your purchase details.</p>

              <div className="progress-container">
                <p><strong>Presale Progress:</strong></p>
                <div className="progress-bar">
                  <div
                    className="progress-bar-fill"
                    style={{ width: `${progressPercentage}%` }}
                  ></div>
                </div>
                <p>{totalSolSold.toFixed(2)} SOL / 1600 SOL</p>
              </div>

            </div>
          )}
        </div>
      )}

      <div className="worldcloudy-container">
        <img src="images/rocket.svg" alt="Rocket" className="rocket-image" />
        <img src="images/gumbyspace.svg" alt="Gumby Space" className="gumbyspace-image" />
      </div>

      <footer className="footer">
        <img src="images/worldcloudy.svg" alt="World Cloudy" className="cloudy-image" />
      </footer>

      {modalMessage && (
        <div className="modal">
          <div className="modal-content">
            <span onClick={() => setModalMessage('')}>&times;</span>
            <p>{modalMessage}</p>
          </div>
        </div>
      )}
    </div>
  );
};

export default PresalePage;
